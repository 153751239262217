<template>
  <div>
    <router-link :to="{ name: 'Finance' }">Zurück zum Finanz-Menü</router-link>
    <v-row>
      <v-col cols="4">
        <!-- Search -->
        <v-text-field
          v-model="searchStr"
          outlined
          label="E-Mail-Adresse, Page_ID oder Rechnungsnummer eingeben"
          append-icon="mdi-magnify"
          @keydown.enter="search"
          @click:append="search"
        ></v-text-field>
      </v-col>
      <v-col cols="1"></v-col>

      <!-- Spacer -->
      <v-col cols="1" md="5">
      </v-col>

      <!-- Buttons -->
      <v-col cols="6" md="2">
        <v-btn width="100%" style="text-transform: none" class="mb-2 unvergessen-shadow white" @click="createInvoice">
          Rechnung erstellen
        </v-btn>
        <v-btn width="100%" style="text-transform: none" class="mb-2 unvergessen-shadow white" @click="exportDialog = true">
          Export
        </v-btn>
        <v-btn width="100%" style="text-transform: none" class="mb-2 unvergessen-shadow white" @click="complete">
          Abschluss
        </v-btn>
      </v-col>
    </v-row>

    <v-alert type="error" v-model="invoiceNotFound">
      Rechnung konnte nicht gefunden werden
    </v-alert>

    <!-- Table Filters -->
    <v-container>
      <v-row>
        <v-col cols="2">
          <v-select
            outlined
            v-model="filterMonth"
            @change="filterTable()"
            :items="filter.time"
            label="Zeitraum"
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            @change="filterTable()"
            v-model="filterStatus"
            :items="filter.status"
            :item-text="filter.status.text"
            :item-value="filter.status.value"
            label="Status"
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            @change="filterTable()"
            v-model="filterProduct"
            :items="filter.product"
            label="Produkte"
            dense
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            @change="filterTable()"
            v-model="filterMethods"
            :items="filter.methods"
            :item-text="filter.methods.text"
            :item-value="filter.methods.value"
            label="Methoden"
            dense
          ></v-select>
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2" style="text-align: right">
          <b>Umsatz: </b>{{ revenue.toFixed(2) }}€ Brutto<br>
          <b>Umsatz: </b>{{ (revenue / 1.19).toFixed(2) }}€ Netto
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="1">
          <div style="height: 50px; margin-bottom: 6px; padding-top: 12px; text-align: center" class="green">
            Bezahlt
          </div>
          <div style="height: 50px; margin-bottom: 6px; padding-top: 12px; text-align: center" class="grey">
            Storniert
          </div>
          <div style="height: 50px; margin-bottom: 6px; padding-top: 12px; text-align: center" class="red">
            Frist
          </div>
          <div style="height: 50px; margin-bottom: 6px; padding-top: 12px; border-style: solid; border-color: grey; text-align: center">
            Offen
          </div>
        </v-col>
        <v-col cols="11">
          <!-- Table -->
          <v-data-table
            :headers="headers"
            :items="invoices"
            class="unvergessen-shadow"
            :items-per-page="-1"
            :item-class="colorRows"
          >
            <!-- Datum -->
            <template v-slot:item._ct="{ item }">
              <div style="width: 100%; height: 100%; cursor: pointer; padding-top: 15px" @click="handleClick(item)">
                <span>{{ new Date(item._ct).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) }}</span>
              </div>
            </template>

            <!-- Invoice Number -->
            <template v-slot:item.invoiceNumber="{ item }">
              <span>
                {{ item.invoiceNumber.displayName }}
              </span>
            </template>

            <!-- Invoice Number -->
            <template v-slot:item.amount="{ item }">
              <span>
                {{ (item.amount / 100).toFixed(2) }}€
              </span>
            </template>

            <!-- Payment Status + Functions -->
            <template v-slot:item.status="{ item }">
              <div v-if="item.functions.button.length > 0">
                <v-menu offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-if="item.status.type === 'paid'" style="cursor: pointer" v-bind="attrs" v-on="on">
                      mdi-checkbox-marked
                    </v-icon>
                    <v-icon v-else-if="item.status.type === 'open'" style="cursor: pointer" v-bind="attrs" v-on="on">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon v-else style="cursor: pointer" v-bind="attrs" v-on="on">
                      mdi-close-box-outline
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(fn, i) in item.functions.button" :key="i" @click="executeFunction(fn, item)">
                      {{ fn === 'storno' ? 'Stornieren' : fn === 'markAsPaid' ? 'Als bezahlt markieren' : fn === 'markAsUnpaid' ? 'Als offen markieren' : fn === 'setDeadline' ? 'Frist setzen' : fn }}
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div v-else>
                <v-icon v-if="item.status.type === 'paid'">
                  mdi-checkbox-marked
                </v-icon>
                <v-icon v-else-if="item.status.type === 'open'">
                  mdi-checkbox-blank-outline
                </v-icon>
                <v-icon v-else>
                  mdi-close-box-outline
                </v-icon>
              </div>
            </template>

            <!-- Deadline -->
            <template v-slot:item.status.deadline="{ item }">
              <span v-if="item.status.deadline.date && (item.status.type === 'deadline' || item.status.type === 'open')">
                Frist {{ item.status.deadline.type }}
                <span v-if="new Date(item.status.deadline.date).setDate(new Date(item.status.deadline.date).getDate() + 1) > Date.now()">endet am </span>
                <span v-else>ist verstrichen: </span>{{ new Date(item.status.deadline.date).toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}) }}
              </span>
              <span v-if="item.status.type === 'storno'">
                Storniert
              </span>
            </template>

            <!-- Actions -->
            <template v-slot:item.functions.actions="{ item}">
              <div v-for="(fn, i) in item.functions.actions" :key="i">
                <span style="text-decoration: underline; cursor: pointer" @click.stop="executeFunction(fn, item)">
                  {{ fn === 'resendInvoice' ? 'Rechnung erneut senden' : fn === 'storno' ? 'Stornieren' : fn === 'sendMail1' ? 'Erste Erinnerung schicken' : fn === 'sendMail2' ? 'Letzte Erinnerung schicken' : fn }}
                </span>
              </div>
            </template>

            <!-- Comments -->
            <template v-slot:item.notes="{ item}">
              <div @click.stop="handleNotes(item)" style="cursor: pointer">
                <span v-if="item.notes.content !== ''">
                  {{ item.notes.content }}
                </span>
                <v-icon v-else>
                  mdi-pencil
                </v-icon>
              </div>
            </template>
          </v-data-table>

          <!-- Load More -->
          <div v-if="index > 0 && !hideInfinite" v-intersect="loadMore"></div>
        </v-col>
      </v-row>
    </v-container>

    <!-- ----------------------Dialogs------------------------ -->

    <!-- Edit Note -->
    <v-dialog max-width="600px" v-model="noteDialog" @click:outside="errorNotes = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorNotes">
          Etwas ist schief gelaufen. Bitte versuche es erneut.
        </v-alert>
        <v-textarea outlined v-model="currentNote.content"></v-textarea>
        <div style="padding: 20px; text-align: center">
          <v-btn class="success" @click="editNote">
            Speichern
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Resend Invoice -->
    <v-dialog max-width="600px" v-model="resendInvoiceDialog" @click:outside="errorResendInvoice = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorResendInvoice">
          Beim Senden der Rechnung ist etwas schiefgelaufen. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Möchtest du die Rechnung wirklich erneut schicken?
          </h2>

          <!-- Show Details -->
          <v-container v-if="currentItem.invoiceNumber" style="text-align: left">
            <v-row>
              <v-col cols="6" class="py-0">
                <b>Rechnungsnummer:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.invoiceNumber.displayName }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>E-Mail-Adresse:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.mail }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Bruttobetrag:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ (currentItem.amount / 100).toFixed(2) }}€
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Typ:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.type }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Frist:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.status.deadline.type }} - {{ new Date(currentItem.status.deadline.date).toLocaleDateString() }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Zahlungsmethode:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.method }}
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="resendInvoiceDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" @click="resendInvoice()">
                  Rechnung erneut senden
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Mark As Unpaid -->
    <v-dialog max-width="600px" v-model="markAsUnpaidDialog" @click:outside="errorMarkAsUnpaid = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorMarkAsUnpaid">
          Die Rechnung konnte nicht als offen markiert werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>

        <!-- If Invoice -->
        <div style="padding: 20px; text-align: center" v-if="currentItem.method === 'invoice'">
          <p>
            Du möchtest gerade eine bereits bezahlte Rechnung als offen markieren.
          </p>

          <p>
            Dies darf nur geschehen, wenn die Rechnung fehlerhaft als bezahlt markiert wurde.
          </p>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="markAsUnpaidDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" @click="markAs(false)">
                  OK, als offen markieren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- Else -->
        <div style="padding: 20px; text-align: center" v-else>
          <p>
            Du möchtest gerade eine Zahlung mit der Zahlungsmethode SEPA / Kreditkarte / Lastschrift als offen markieren, weil der Kunde die Zahlung angefochten oder storniert hat.
          </p>

          <p>
            Dies ist aus buchhalterischen Gründen nicht möglich.<br>
            In diesem Fall wird eine neue Rechnung mit den selben Zahlungsinformationen und eigenen E-Mails erstellt.
          </p>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="markAsUnpaidDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" @click="markAsUnpaidFollowUpDialog = true; markAsUnpaidDialog = false">
                  Ok, weiter
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Mark As Unpaid Follow up -->
    <v-dialog max-width="600px" v-model="markAsUnpaidFollowUpDialog" persistent>
      <v-card style="padding: 20px">
        <div style="padding: 20px; text-align: center">
          <h2>
            Soll zu dieser nun wieder offenen Rechnung direkt eine Zahlungserinnerung erfolgen?
          </h2>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="firstMailCheckDialog = true; markAsUnpaidFollowUpDialog = false">
                  Ja, Erinnerung schicken
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="markAsUnpaidFollowUpDialog = false; setUnpaidDeadlineDialog = true">
                  Nein, Frist setzen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Set Unpaid Deadline -->
    <v-dialog max-width="600px" v-model="setUnpaidDeadlineDialog" persistent>
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorSetDeadline">
          Die neue Frist konnte nicht gesetzt werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <v-alert type="error" v-model="errorMarkAsUnpaid">
          Die Rechnung konnte nicht als offen markiert werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Bitte lege zu dieser nun wieder offenen Zahlung eine Frist fest
          </h2>

          <div v-if="currentItem.status && currentItem.status.deadline" style="text-align: left; width: 80%; margin: 16px auto 0">
            <label for="deadlineDatePicker">
              Datum
            </label>
            <v-text-field
              id="deadlineDatePicker"
              type="date"
              solo
              outlined
              v-model="unpaidDate"
            ></v-text-field>
          </div>

          <v-container>
            <v-btn style="width: 100%" class="success" @click="markAs(false)" :disabled="unpaidDate === ''">
              Frist speichern
            </v-btn>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- First Mail Check Dialog -->
    <v-dialog max-width="600px" v-model="firstMailCheckDialog" persistent>
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorSendMail">
          Die Mail konnte nicht gesendet werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Möchtest du jetzt die erste Zahlungserinnerung rausschicken?
          </h2>

          <v-container>
            <v-alert type="error" v-model="errorMarkAsUnpaid">
              Die Rechnung konnte nicht als offen markiert werden. Bitte versuche es erneut.<br>
              <b>Error: </b>{{ errorText }}
            </v-alert>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="firstMailCheckDialog = false; markAsUnpaidFollowUpDialog = true">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" @click="sendMailNow = true; markAs(false)">
                  Erinnerung schicken
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Mark As Paid -->
    <v-dialog max-width="600px" v-model="markAsPaidDialog" @click:outside="errorMarkAsPaid = false; successMarkAsPaid = false">
      <v-card style="padding: 20px">
        <div style="padding: 20px; text-align: center">
          <h2 v-if="!correctAmount">
            Bitte gleiche unten stehende Zahlunfsinformationen mit dem tatsächlichen Zahlungseingang ab
          </h2>
          <h2 v-else>
            Du kannst hier nur den Bruttobetrag ändern, wenn ein Kunde zu viel oder zu wenig bezahlt hat
          </h2>

          <!-- Show Details -->
          <v-container v-if="currentItem.invoiceNumber && !correctAmount" style="text-align: left">
            <v-row>
              <v-col cols="6" class="py-0">
                <b>E-Mail-Adresse:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.mail }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Bruttobetrag:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ (currentItem.amount / 100).toFixed(2) }}€
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Rechnungsnummer:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.invoiceNumber.displayName }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Leistungszeitraum:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ new Date(currentItem.runtime.start).toLocaleDateString() }} - {{ new Date(currentItem.runtime.end).toLocaleDateString() }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Rechnungsdatum:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ new Date(currentItem.status.deadline.date).toLocaleDateString() }}
              </v-col>
              <v-col cols="6" class="py-0">
                <b>Zahlungsmethode:</b>
              </v-col>
              <v-col cols="6" class="py-0">
                {{ currentItem.method }}
              </v-col>
            </v-row>
          </v-container>

          <v-container v-if="currentItem.invoiceNumber && correctAmount">
            <v-alert type="error" v-model="updateAmountError">
              Der Betrag konnte nicht gespeichert werden. Bitte versuche es erneut.<br>
              <b>Error:</b> {{ errorText }}
            </v-alert>
            Sind andere Angaben falsch, wende dich bitte an den Admin
            <div class="my-4">
              <v-row>
                <v-text-field
                  label="Bruttobetrag:"
                  @change="tmpAmount = $event * 100"
                  :value="(tmpAmount / 100).toFixed(2)"
                  type="number"
                  prefix="€"
                ></v-text-field>
              </v-row>
            </div>
          </v-container>

          <v-container>
            <v-row v-if="!correctAmount">
              <v-col cols="6">
                <v-btn style="width: 100%" @click="correctAmount = true; tmpAmount = currentItem.amount">
                  Angaben korrigieren
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="lastCheckDialog = true; markAsPaidDialog = false">
                  Angaben bestätigen
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="correctAmount = false; tmpAmount = 0">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="saveNewAmount(tmpAmount)">
                  Speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Last Check Mark As Paid -->
    <v-dialog max-width="600px" v-model="lastCheckDialog" @click:outside="lastCheckDialog = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorMarkAsPaid">
          Die Rechnung konnte nicht als bezahlt markiert werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Möchtest du diese Rechnung wirklich als bezahlt markieren?
          </h2>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="lastCheckDialog = false; markAsPaidDialog = true">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" @click="markAs(true); lastCheckDialog = false">
                  Als bezahlt markieren
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Send Mail -->
    <v-dialog max-width="600px" v-model="sendMailDialog" @click:outside="sendMailDialog = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorSendMail">
          Die Mail konnte nicht gesendet werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2 v-if="currentFn === 'sendMail2'">
            Möchtest du jetzt die letzte Zahlungserinnerung schicken?
          </h2>
          <h2 v-else>
            Möchtest du jetzt die erste Zahlungserinnerung schicken?
          </h2>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="sendMailDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" @click="currentFn === 'sendMail2' ? sendMail(2) : sendMail(1)">
                  Erinnerung schicken
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Storno Dialog -->
    <v-dialog max-width="600px" v-model="stornoDialog" @click:outside="stornoDialog = false">
      <unvergessen-storno-dialog :item="currentItem" :dialog="stornoDialog" @closeDialog="stornoDialog = false" @updateObject="updateObject($event)"></unvergessen-storno-dialog>
    </v-dialog>

    <!-- Set Deadline -->
    <v-dialog max-width="600px" v-model="setDeadlineDialog" @click:outside="setDeadlineDialog = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorSetDeadline">
          Die neue Frist konnte nicht gesetzt werden. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Frist bearbeiten
          </h2>

          <div v-if="currentItem.status && currentItem.status.deadline" style="text-align: left; width: 80%; margin: 16px auto 0">
            <label for="deadlinePicker">
              Art der Frist
            </label>
            <v-select
              id="deadlinePicker"
              :items="[1, 2, 3]"
              v-model="tmpDeadline.type"
              @input="changeTypeDialog($event)"
              solo
              outlined
              :value="parseInt(currentItem.status.deadline.type)"
              label="Art der Frist"
            ></v-select>
            <label for="deadlineDatePicker">
              Datum
            </label>
            <v-text-field
              id="deadlineDatePicker"
              type="date"
              solo
              outlined
              @input="tmpDeadline.date = $event; disableDeatlineBtn = false"
              :value="currentItem.status.deadline.date"
            ></v-text-field>
          </div>

          <v-container>
            <v-row>
              <v-col cols="6">
                <v-btn style="width: 100%" @click="setDeadlineDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn style="width: 100%" class="success" :disabled="disableDeatlineBtn" @click="setDeadline()">
                  Frist speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Export -->
    <v-dialog max-width="600px" v-model="exportDialog" @click:outside="errorExport = false">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorExport">
          Beim Exportieren ist etwas schiefgelaufen. Bitte versuche es erneut.<br>
          <b>Error: </b>{{ errorText }}
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Bitte wähle den Zeitraum, für den der Export erstellt werden soll
          </h2>
          <v-container>
            <v-row>
              <v-col cols="6">
                Start:
                <input type="date" v-model="exportStartDate" class="mb-8" style="border-style: solid; padding: 2px; border-radius: 4px" />
                <v-btn style="width: 100%" @click="exportDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                End:
                <input type="date" v-model="exportEndDate" class="mb-8" style="border-style: solid; padding: 2px; border-radius: 4px" />
                <v-btn style="width: 100%" class="success" @click="exportInvoices(exportStartDate, exportEndDate)">
                  Export erstellen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <!-- Filter Table -->
    <v-dialog max-width="600px" v-model="filterMonthDialog">
      <v-card style="padding: 20px">
        <v-alert type="error" v-model="errorCustomMonth">
          Bitte wähle keinen negativen Zeitraum
        </v-alert>
        <div style="padding: 20px; text-align: center">
          <h2>
            Bitte wähle den Zeitraum
          </h2>

          <v-container>
            <v-row>
              <v-col cols="6">
                Start:
                <input type="date" v-model="filterStartDate" class="mb-8" style="border-style: solid; padding: 2px; border-radius: 4px" />
                <v-btn style="width: 100%" @click="filterMonthDialog = false">
                  Abbrechen
                </v-btn>
              </v-col>
              <v-col cols="6">
                End:
                <input type="date" v-model="filterEndDate" class="mb-8" style="border-style: solid; padding: 2px; border-radius: 4px" />
                <v-btn style="width: 100%" class="success" @click="filterCustomMonth(filterStartDate, filterEndDate)">
                  Tabelle filtern
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>

    <unvergessen-success-dialog :dialog="successMarkAsPaid" @close="successMarkAsPaid = false; markAsPaidDialog = false" heading="Die Rechnung wurde erfolgreich als bezahlt markiert" buttonText="Schließen"></unvergessen-success-dialog>
    <unvergessen-success-dialog :dialog="successResendInvoice" @close="successResendInvoice = false; resendInvoiceDialog = false" heading="Die Rechnung wurde erfolgreich versandt" buttonText="Schließen"></unvergessen-success-dialog>
    <unvergessen-success-dialog :dialog="successSendMail" @close="successSendMail = false; sendMailDialog = false" heading="Die Mail wurde erfolgreich versandt" buttonText="Schließen"></unvergessen-success-dialog>
    <unvergessen-success-dialog :dialog="successSetDeadline" @close="successSetDeadline = false; setDeadlineDialog = false" heading="Die neue Frist wurde erfolgreich gesetzt" buttonText="Schließen"></unvergessen-success-dialog>

  </div>
</template>

<script>
import StornoDialog from '@/components/finance/accounting/Storno.vue'
import SuccessDialog from '@/components/global/SuccessDialog.vue'
import axios from 'axios'

export default {
  data () {
    return {
      revenue: 0,
      index: 0,
      hideInfinite: false,
      currentNote: '',
      currentItem: {},
      currentIndex: -1,
      currentFn: '',
      invoiceNotFound: false,
      menu: false,
      searchStr: '',
      invoices: [],
      filter: {
        time: [],
        status: [
          {
            text: 'Alle Zahlungen',
            value: 'all'
          },
          {
            text: 'Bezahlt',
            value: 'paid'
          },
          {
            text: 'Offen',
            value: 'open'
          },
          {
            text: 'Frist abgelaufen',
            value: 'deadline'
          },
          {
            text: 'Storniert',
            value: 'storno'
          }
        ],
        product: [
          'Produkte (Alle)',
          'IE',
          'FE',
          'GP',
          'GPE',
          'K',
          'KA',
          'VM',
          'BL',
          'TP',
          'AG'
        ],
        methods: [
          {
            text: 'Methoden (Alle)',
            value: 'all'
          },
          {
            text: 'PayPal',
            value: 'paypal'
          },
          {
            text: 'Kreditkarte',
            value: 'card'
          },
          {
            text: 'SEPA',
            value: 'sepa'
          },
          {
            text: 'Rechnung',
            value: 'invoice'
          }
        ]
      },
      exportStartDate: null,
      exportEndDate: null,
      filterStartDate: null,
      filterEndDate: null,
      correctAmount: false,
      headers: [
        {
          text: 'Datum',
          align: 'start',
          value: '_ct'
        },
        { text: 'R-Nr.', value: 'invoiceNumber' },
        { text: 'E-Mail-Adresse', value: 'mail' },
        { text: 'Betrag', value: 'amount' },
        { text: 'Bezahlt?', value: 'status' },
        { text: 'Fristen', value: 'status.deadline' },
        { text: 'Handlungen', value: 'functions.actions' },
        { text: 'Kommentar', value: 'notes' }
      ],
      sendMailNow: false,
      unpaidDate: '',
      // Tmp Variables
      tmpAmount: 0,
      tmpDeadline: {
        type: 1,
        date: ''
      },
      // Filter
      filterMonth: 'Aktueller Monat',
      filterStatus: 'all',
      filterProduct: 'Produkte (Alle)',
      filterMethods: 'all',
      filterCustomMonthFlag: false,
      // Errors
      errorText: '',
      errorNotes: false,
      errorExport: false,
      errorResendInvoice: false,
      errorMarkAsUnpaid: false,
      errorMarkAsPaid: false,
      updateAmountError: false,
      errorSetDeadline: false,
      errorSendMail: false,
      errorCustomMonth: false,
      // Success
      successResendInvoice: false,
      successMarkAsUnpaid: false,
      successMarkAsPaid: false,
      successSetDeadline: false,
      successSendMail: false,
      // Dialogs
      noteDialog: false,
      exportDialog: false,
      resendInvoiceDialog: false,
      markAsUnpaidDialog: false,
      markAsPaidDialog: false,
      lastCheckDialog: false,
      setDeadlineDialog: false,
      setUnpaidDeadlineDialog: false,
      sendMailDialog: false,
      stornoDialog: false,
      markAsUnpaidFollowUpDialog: false,
      firstMailCheckDialog: false,
      filterMonthDialog: false,
      // Disable
      disableDeatlineBtn: true
    }
  },
  methods: {
    changeTypeDialog (type) {
      this.tmpDeadline.type = type
    },
    parseDate (input) {
      return input.replace('Januar', 'January')
        .replace('Februar', 'February')
        .replace('März', 'March')
        .replace('Mai', 'May')
        .replace('Juni', 'June')
        .replace('Juli', 'July')
        .replace('Oktober', 'October')
        .replace('Dezember', 'December')
    },
    filterCustomMonth (startDate, endDate) {
      if (new Date(endDate) < new Date(startDate)) {
        this.errorCustomMonth = true
        return
      } else {
        this.errorCustomMonth = false
      }
      // close Dialog
      this.filterMonthDialog = false
      // compute corret name to display
      var dateString = startDate.split('-').reverse().join('.') + ' - ' + endDate.split('-').reverse().join('.')
      this.filter.time.push(dateString)
      this.filterMonth = dateString
      // filter correctly
      this.filterTable(startDate, endDate)
    },
    filterTable (startDate = '', endDate = '') {
      var data = {}

      var lastElement = this.filter.time[this.filter.time.length - 1]
      if (!this.filterMonth.includes('-') && lastElement !== 'Benutzerdefiniert') {
        this.filter.time.pop()
      }

      // Month
      var d = null
      if (this.filterMonth === 'Aktueller Monat') {
        d = new Date()
      } else if (this.filterMonth === 'Benutzerdefiniert') {
        this.filterMonthDialog = true
        return
      } else if (this.filterMonth === 'Heute') {
        this.filterCustomMonth(new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0])
        return
      } else if (this.filterMonth === 'Gestern') {
        const start = new Date()
        start.setDate(start.getDate() - 1)
        this.filterCustomMonth(start.toISOString().split('T')[0], start.toISOString().split('T')[0])
        return
      } else {
        d = new Date(this.parseDate(this.filterMonth))
      }

      var period = {}
      if (startDate !== '' && endDate !== '') {
        period = {
          start: startDate,
          end: endDate
        }
      } else {
        period = {
          start: new Date(d.getFullYear(), d.getMonth(), 1),
          end: new Date(d.getFullYear(), d.getMonth() + 1, 0)
        }
      }

      data.period = period

      // Status
      if (this.filterStatus === 'all') {
        // Delete Status from object if it exists
        if (data.status) delete data.status
      } else {
        data.status = this.filterStatus
      }

      // Products
      if (this.filterProduct === 'Produkte (Alle)') {
        // Delete Status from object if it exists
        if (data.product) delete data.product
      } else {
        data.product = this.filterProduct
      }

      // Methods
      if (this.filterMethods === 'all') {
        // Delete Status from object if it exists
        if (data.paymentMethod) delete data.paymentMethod
      } else {
        data.paymentMethod = this.filterMethods
      }

      // Request
      axios.post('/crm/payment/all', data)
        .then(res => {
          this.invoices = res.data
        })
        .catch(() => {})
    },
    sendMail (type) {
      axios.post('/crm/payment/sendMail' + type, { id: this.currentItem._id })
        .then(res => {
          this.updateObject(res.data)
          this.errorSendMail = false
          this.successSendMail = true
          this.firstMailCheckDialog = false
        })
        .catch(error => {
          this.errorSendMail = true
          this.successSendMail = false
          this.errorText = error
        })
    },
    setDeadline () {
      // Todo: Check if correct
      axios.post('/crm/payment/setDeadline', { id: this.currentItem._id, deadlineType: this.tmpDeadline.type, date: new Date(this.tmpDeadline.date) })
        .then(res => {
          this.updateObject(res.data)
          this.tmpDeadline = {
            type: 1,
            date: ''
          }
          this.errorSetDeadline = false
          this.successSetDeadline = true
          this.disableDeatlineBtn = true
        })
        .catch(error => {
          this.successSetDeadline = false
          this.errorSetDeadline = true
          this.errorText = error
        })
    },
    saveNewAmount (amount) {
      // Todo: Check if correct
      axios.patch('/crm/payment/amount', { id: this.currentItem._id, amount: amount })
        .then(res => {
          this.updateObject(res.data)
          this.updateAmountError = false
          this.correctAmount = false
          this.tmpAmount = 0
        })
        .catch(error => {
          this.updateAmountError = true
          this.errorText = error
        })
    },
    markAs (paid) {
      // Todo: Check if correct
      if (paid) {
        axios.post('/crm/payment/markAsPaid', { id: this.currentItem._id })
          .then(res => {
            Object.assign(this.invoices[this.currentIndex], res.data)
            this.successMarkAsPaid = true
            this.errorMarkAsPaid = false
            this.markAsPaidDialog = false
          })
          .catch(error => {
            this.errorMarkAsPaid = true
            this.successMarkAsPaid = false
            this.errorText = error
          })
      } else {
        var data = {
          id: this.currentItem._id
        }
        if (this.sendMailNow) {
          data.sendMailNow = true
        }
        if (this.unpaidDate !== '') {
          data.date = new Date(this.unpaidDate)
        }
        axios.post('/crm/payment/markAsUnpaid', data)
          .then(res => {
            this.successMarkAsUnpaid = true
            this.errorMarkAsUnpaid = false
            this.sendMailNow = false
            this.unpaidDate = ''
            this.updateObject(res.data)
          })
          .catch(error => {
            this.errorMarkAsUnpaid = true
            this.successMarkAsUnpaid = false
            this.errorText = error
          })
      }
    },
    executeFunction (fn, item) {
      // Decide, which Dialog to open and what to do
      this.currentItem = item
      this.currentIndex = this.invoices.indexOf(item)
      this.currentFn = fn
      // Execute the correct function
      switch (fn) {
        case 'resendInvoice':
          this.resendInvoiceDialog = true
          break
        case 'markAsUnpaid':
          this.markAsUnpaidDialog = true
          break
        case 'markAsPaid':
          this.markAsPaidDialog = true
          break
        case 'setDeadline':
          if (item.status.deadline.type) {
            this.tmpDeadline.type = parseInt(item.status.deadline.type)
          } else {
            this.tmpDeadline.type = 1
          }
          this.setDeadlineDialog = true
          break
        case 'sendMail1':
          this.sendMailDialog = true
          break
        case 'sendMail2':
          this.sendMailDialog = true
          break
        case 'storno':
          this.stornoDialog = true
          break
        default:
          break
      }
    },
    isInPast (date1) {
      return new Date(date1) < Date.now()
    },
    colorRows (item) {
      // Todo: Color Rows correctly
      var date = ''
      var flag = false

      if (item.status.deadline) {
        date = new Date(item.status.deadline.date)
        flag = date.setDate(date.getDate() + 1) < new Date()
      }

      if (item.status.deadline && item.status.type === 'open' && flag) {
        return 'red'
      } else {
        switch (item.status.type) {
          case 'paid':
            return 'green'
          case 'storno':
            return 'grey'
          default:
            return 'white'
        }
      }
    },
    resendInvoice () {
      // Todo: Test Resend Invoice
      axios.post('/crm/payment/resendInvoice', { id: this.currentItem._id })
        .then(res => {
          this.updateObject(res.data)
          this.errorResendInvoice = false
          this.successResendInvoice = true
        })
        .catch(error => {
          this.errorResendInvoice = true
          this.successResendInvoice = false
          this.errorText = error
        })
    },
    createInvoice () {
      // Todo: Create Invoice
    },
    exportInvoices (startDate, endDate) {
      // Todo: Test Export Invoices
      axios.post('/crm/payment/export', { period: { start: new Date(startDate), end: new Date(endDate) }, final: false })
        .then(res => {
          const blob = new Blob(['\ufeff' + res.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'export.csv'
          link.click()
          this.errorExport = false
          this.errorText = ''
        })
        .catch(error => {
          this.errorExport = true
          this.errorText = error
        })
    },
    complete () {
      // Todo: Complete
    },
    handleClick (value) {
      this.$router.push({ name: 'AccountingUser', params: { uid: value._id } })
    },
    handleNotes (item) {
      this.currentNote = item.notes
      this.currentItem = item
      this.currentIndex = this.invoices.indexOf(item)
      this.noteDialog = true
    },
    editNote () {
      this.currentItem.notes = this.currentNote
      if (this.currentIndex > -1) {
        axios.patch('/crm/payment/notes', { id: this.currentItem._id, notes: this.currentNote.content })
          .then(() => {
            this.updateObject(this.currentItem)
            this.currentNote = {}
            this.noteDialog = false
            this.errorNotes = false
          })
          .catch(() => {
            this.errorNotes = true
          })
      }
    },
    search () {
      axios.post('/crm/payment/search', { key: this.searchStr })
        .then(res => {
          this.invoices = res.data
        })
        .catch(() => {})
    },
    loadMore () {
      axios.post('/crm/payment/all', { params: { set: this.index } })
        .then(res => {
          if (res.data.length === 0) {
            this.hideInfinite = true
          } else {
            this.invoices = this.invoices.concat(res.data)
            this.index++
          }
        })
    },
    updateObject (data) {
      Object.assign(this.invoices[this.currentIndex], data)
      this.currentIndex = -1
      if (!this.markAsPaidDialog) {
        this.currentItem = {}
      }
      this.errorText = ''
      this.currentFn = ''
    }
  },
  watch: {
    invoices () {
      this.revenue = 0
      this.invoices.forEach(element => {
        this.revenue += element.amount / 100
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.markAsPaidDialog ||
    this.lastCheckDialog ||
    this.noteDialog ||
    this.exportDialog ||
    this.resendInvoiceDialog ||
    this.markAsUnpaidDialog ||
    this.setDeadlineDialog ||
    this.setUnpaidDeadlineDialog ||
    this.sendMailDialog ||
    this.stornoDialog ||
    this.markAsUnpaidFollowUpDialog ||
    this.firstMailCheckDialog ||
    this.filterMonthDialog) {
      this.markAsPaidDialog = false
      this.lastCheckDialog = false
      this.noteDialog = false
      this.exportDialog = false
      this.resendInvoiceDialog = false
      this.markAsUnpaidDialog = false
      this.setDeadlineDialog = false
      this.sendMailDialog = false
      this.stornoDialog = false
      this.markAsUnpaidFollowUpDialog = false
      this.firstMailCheckDialog = false
      this.filterMonthDialog = false
      next(false)
    } else {
      next()
    }
  },
  created () {
    axios.post('/crm/payment/all', {})
      .then(res => {
        this.invoices = res.data
      })
      .catch(() => {})

    // ---------------- Calculate Filters ------------------

    // Calculate possible dates to filter
    var d = new Date()

    // Bug Workaround: If Day is 31 => calculate everything for Day 30
    if (d.getDate() === 31) {
      d.setDate(d.getDate() - 1)
    }
    var dArr = ['Aktueller Monat', (d.getMonth() - 1), d.getMonth() - 2, d.getMonth() - 3, 'Heute', 'Gestern', 'Benutzerdefiniert']
    for (var i = 1; i < dArr.length - 3; i++) {
      dArr[i] = new Date(new Date(d).setDate(28)).setMonth(dArr[i])
      dArr[i] = new Date(dArr[i]).toLocaleDateString('de-DE', { year: 'numeric', month: 'long' })
    }
    this.filter.time = dArr
  },
  components: {
    unvergessenStornoDialog: StornoDialog,
    unvergessenSuccessDialog: SuccessDialog
  }
}
</script>

<style scoped>
.card {
  text-align: center;
  min-width: 70px;
  border-radius: 10px !important;
  margin-right: 30px
}

* >>> .v-responsive__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center
}

* >>> td {
  font-size: 12px !important;
}
</style>
